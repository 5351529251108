import { Button, DatePicker, Form, Input, Select } from "antd";
import { SaleContext } from "../../contexts/SaleContext";
import { useContext } from "react";
import ParseHelper from "../../helpers/ParseHelper";
import DateUtil from "../../utils/DateUtil";
import dayjs from "dayjs";

const SaleFilter = () => {

    const { getSalesByFilter } = useContext(SaleContext);

    const onFinish = (values) => {
        getSalesByFilter({
            full_name: ParseHelper.parseString(values.full_name),
            document_number: ParseHelper.parseString(values.document_number),
            start_date: DateUtil.ToServerString(values.start_datetime),
            end_date: DateUtil.ToServerEndString(values.end_datetime),
            sale_type: ParseHelper.parseString(values.sale_type)
        });
    };

    return (
        <Form initialValues={{
            start_datetime: dayjs().set('hour', 0).set('minute', 0).set('second', 0)
        }} className="FilterContainer" layout="vertical" onFinish={onFinish} autoComplete="off">
            <Form.Item name={"full_name"} label={"Filtro por cliente"}>
                <Input />
            </Form.Item>
            <Form.Item name={"document_number"} label={"Filtro por número de documento"}>
                <Input />
            </Form.Item>
            <Form.Item name={"start_datetime"} label={"Filtro por fecha de inicio"}>
                <DatePicker format="DD/MM/YYYY" style={{ width: '250px' }} />
            </Form.Item>
            <Form.Item name={"end_datetime"} label={"Filtro por fecha fin"}>
                <DatePicker format="DD/MM/YYYY" style={{ width: '250px' }} />
            </Form.Item>
            <Form.Item name={"sale_type"} label={"Filtro por tipo de venta"}>
                <Select
                    style={{ width: '250px' }}
                    options={[
                        { label: 'Factura', value: 'FACTURA' },
                        { label: 'Boleta', value: 'BOLETA' }
                    ]}
                    allowClear={true}
                    placeholder={'Selecciona una opción'}
                />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">Buscar</Button>
            </Form.Item>
        </Form>
    );
};

export default SaleFilter;