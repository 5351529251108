import { createContext, useContext, useEffect, useState } from "react";
import LocalStorageHelper from "../helpers/LocalStorageHelper";
import { WEBSOCKET_URL } from "../config/envConfig";
import { GET_ALL_CURRENT_ROOM_CLEANING_RECORDS } from "../config/constants";
import { LayoutContext } from "./LayoutContext";

export const CleaningMonitoringContext = createContext();

export const CleaningMonitoringProvider = ({ children }) => {

    const { cleaningMonitoringData, cleaningMonitoringWebsocket } = useContext(LayoutContext);

    const [showView, setShowView] = useState('cleaning_monitoring_records');

    const [updateStatusModel, setUpdateStatusModel] = useState(null);

    const filterData = (values) => {
        if (cleaningMonitoringWebsocket !== null) {
            cleaningMonitoringWebsocket.send(JSON.stringify({
                accessToken: LocalStorageHelper.getAccessTokenData(),
                service: GET_ALL_CURRENT_ROOM_CLEANING_RECORDS,
                params: { status: values.status },
                websocket_connection_id: cleaningMonitoringData.websocket_connection_id
            }));
        }
    };

    return (
        <CleaningMonitoringContext.Provider value={{
            showView, setShowView,
            updateStatusModel, setUpdateStatusModel,
            filterData
        }}>
            {children}
        </CleaningMonitoringContext.Provider>
    );

};