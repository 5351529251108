import { Form, Input, Modal } from "antd";
import SettingKeyHelper from "../../helpers/SettingKeyHelper";
import RulesHelper from "../../helpers/RulesHelper";
import { SettingContext } from "../../contexts/SettingContext";
import { useContext } from "react";

const SettingModal = () => {
    const { updateSetting, setModel, setOpenModal, form, model, openModal } = useContext(SettingContext);
    const onClose = () => {
        form.resetFields();
        setModel(null);
        setOpenModal(false);
    };
    const onFinish = (values) => {
        const { setting_value } = values;
        updateSetting(setting_value);
    };
    return (model && (
        <Modal title={'ACTUALIZAR CONFIGURACIÓN'} open={openModal} onCancel={onClose} onOk={() => form.submit()}>
            <Form form={form} name="basic" layout="vertical" onFinish={onFinish} autoComplete="off">
                <Form.Item label="Configuración" name="setting_description">
                    <Input disabled />
                </Form.Item>
                {
                    form.getFieldValue('setting_key') === SettingKeyHelper.getCompanyPhone() && (
                        <Form.Item label="Valor" name="setting_value" rules={RulesHelper.phone()}>
                            <Input />
                        </Form.Item>
                    )
                }
                {
                    form.getFieldValue('setting_key') === SettingKeyHelper.getCompanyEmail() && (
                        <Form.Item label="Valor" name="setting_value" rules={RulesHelper.email()}>
                            <Input />
                        </Form.Item>
                    )
                }
                {
                    form.getFieldValue('setting_key') === SettingKeyHelper.getCompanyAddress() && (
                        <Form.Item label="Valor" name="setting_value" rules={RulesHelper.max(50)}>
                            <Input />
                        </Form.Item>
                    )
                }
                {
                    form.getFieldValue('setting_key') === SettingKeyHelper.getCompanyCity() && (
                        <Form.Item label="Valor" name="setting_value" rules={RulesHelper.max(50)}>
                            <Input />
                        </Form.Item>
                    )
                }
                {
                    form.getFieldValue('setting_key') === SettingKeyHelper.getCompanyLocation() && (
                        <Form.Item label="Valor" name="setting_value" rules={RulesHelper.max(50)}>
                            <Input />
                        </Form.Item>
                    )
                }
                {
                    form.getFieldValue('setting_key') === SettingKeyHelper.getCompanyName() && (
                        <Form.Item label="Valor" name="setting_value" rules={RulesHelper.max(50)}>
                            <Input />
                        </Form.Item>
                    )
                }
                {
                    form.getFieldValue('setting_key') === SettingKeyHelper.getGracePeriod() && (
                        <Form.Item label="Valor" name="setting_value" rules={RulesHelper.positiveNumber()}>
                            <Input />
                        </Form.Item>
                    )
                }
            </Form>
        </Modal>
    ));
};

export default SettingModal;