import { useContext } from "react";
import SaleFilter from "./SaleFilter";
import SaleRecords from "./SaleRecords";
import { SaleContext } from "../../contexts/SaleContext";
import { Breadcrumb, Button, Card } from "antd";
import { SaleDetailProvider } from "../../contexts/SaleDetailContext";
import SaleDetailDescription from "../SaleDetailComponents/SaleDetailDescription";
import SaleDetailConsumptions from "../SaleDetailComponents/SaleDetailConsumptions";
import SaleDetailModal from "../SaleDetailComponents/SaleDetailModal";
import { FileExcelOutlined } from '@ant-design/icons';

const SaleContainer = () => {

    const {
        showView, setShowView,
        saleSelected, setSaleSelected,
        downloadReport
    } = useContext(SaleContext);

    return (
        <>
            {showView === 'sales' && (
                <>
                    <Card title={'LISTA DE VENTAS'}>
                        <div style={{ marginBottom: '20px', textAlign: 'end' }}>
                            <Button onClick={() => { downloadReport(); }} icon={<FileExcelOutlined />}>Descargar Reporte</Button>
                        </div>
                        <SaleFilter />
                        <SaleRecords />
                    </Card>
                </>
            )}

            {showView === 'sale_detail' && (
                <>
                    <Breadcrumb
                        style={{ marginBottom: '20px' }}
                        items={[
                            {
                                title: <div style={{ cursor: 'pointer' }}>Ventas</div>,
                                onClick: () => {
                                    setSaleSelected(null);
                                    setShowView('sales');
                                }
                            },
                            {
                                title: 'Detalle de venta',
                            },
                        ]}
                    />

                    <SaleDetailProvider sale={saleSelected}>
                        <SaleDetailDescription />
                        <SaleDetailConsumptions />
                        <SaleDetailModal />
                    </SaleDetailProvider>
                </>
            )}

        </>
    );

};

export default SaleContainer;