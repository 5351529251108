import { useContext } from "react";
import { LaundryMonitoringContext } from "../../contexts/LaundryMonitoringContext";
import { Button, Card, Empty } from "antd";
import DateUtil from "../../utils/DateUtil";
import { LayoutContext } from "../../contexts/LayoutContext";

const LaundryMonitoringRecords = () => {

    const { laundryMonitoringData } = useContext(LayoutContext);
    const { setLaundryRecordModel, setShowView } = useContext(LaundryMonitoringContext);

    const handleChangeStatus = (values) => {
        setLaundryRecordModel(values);
        setShowView('laundry_record_update');
    };

    return (
        <>
            {laundryMonitoringData.length === 0 && (
                <Empty
                    style={{ maxWidth: '100%' }}
                    description={'No hay solicitudes de lavado.'}
                />
            )}

            <div className="MonitoringList">

                {laundryMonitoringData.map((model, index) => (
                    <div key={index}>

                        {model.laundry_status === 'PENDIENTE' && (
                            <Card bordered={false} style={{ backgroundColor: '#730a0a', color: '#FFF' }}>
                                <div style={{ marginBottom: '10px' }}>
                                    <div><strong>Cliente:</strong> {model.customer_full_name}</div>
                                    <div><strong>Estado:</strong> PENDIENTE</div>
                                    <div><strong>Peso(k):</strong> {model.laundry_weight}</div>
                                    <div><strong>Registrado:</strong> {DateUtil.ToSimpleString(model.laundry_created_at)}</div>
                                    {model.cleaner && (
                                        <div><strong>Responsable:</strong> {model.cleaner}</div>
                                    )}
                                </div>
                                <div className="ColumnContainer ColumnContainerVentas" style={{ width: '100%', marginBottom: '10px' }}>
                                    <Button onClick={() => { handleChangeStatus(model); }}>LIMPIAR</Button>
                                </div>
                            </Card>
                        )}

                        {model.laundry_status === 'EN LAVADO' && (
                            <Card bordered={false} style={{ backgroundColor: '#0c5707', color: '#FFF' }}>
                                <div style={{ marginBottom: '10px' }}>
                                    <div><strong>Cliente:</strong> {model.customer_full_name}</div>
                                    <div><strong>Estado:</strong> EN LAVADO</div>
                                    <div><strong>Peso(k):</strong> {model.laundry_weight}</div>
                                    <div><strong>Registrado:</strong> {DateUtil.ToSimpleString(model.laundry_created_at)}</div>
                                    {model.cleaner && (
                                        <div><strong>Responsable:</strong> {model.cleaner}</div>
                                    )}
                                </div>
                                <div className="ColumnContainer ColumnContainerVentas" style={{ width: '100%', marginBottom: '10px' }}>
                                    <Button onClick={() => { handleChangeStatus(model); }}>TERMINAR LIMPIEZA</Button>
                                </div>
                            </Card>
                        )}

                    </div>
                ))}
            </div>
        </>
    );

};

export default LaundryMonitoringRecords;