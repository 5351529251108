import { createContext, useEffect, useState } from "react";
import LocalStorageHelper from "../helpers/LocalStorageHelper";
import { WEBSOCKET_URL } from "../config/envConfig";

export const LaundryMonitoringContext = createContext();

export const LaundryMonitoringProvider = ({ children }) => {

    const [showView, setShowView] = useState('laundry_monitoring_records');

    const [laundryRecordModel, setLaundryRecordModel] = useState(null);

    return (
        <LaundryMonitoringContext.Provider value={{
            showView, setShowView,
            laundryRecordModel, setLaundryRecordModel
        }}>
            {children}
        </LaundryMonitoringContext.Provider>
    );
}