export const defaultSaleResumen = [
    { key: '1', label: 'Noches', children: '0' },
    { key: '2', label: 'Precio Habitación', children: 'S/ 0.00' },
    { key: '3', label: 'Total', children: 'S/ 0.00' }
];

export const USER_ACTIVE_VERIFICATION = 'USER_ACTIVE_VERIFICATION';
export const GET_ALL_DISPONIBILITIES = 'GET_ALL_DISPONIBILITIES';
export const GET_ALL_CURRENT_ROOM_CLEANING_RECORDS = 'GET_ALL_CURRENT_ROOM_CLEANING_RECORDS';
export const SERVICE_ADMIN_DASHBOARD = 'SERVICE_ADMIN_DASHBOARD';
export const WEBSOCKET_SERVICE_LAUNDRY_MONITORING_DATA = 'SERVICE_MONITORING_LAUNDRY';
export const WEBSOCKET_KEEP_ALIVE = 'KEEP_ALIVE';

export const DASHBOARD_REAL_TIME = 'DASHBOARD_REAL_TIME';
export const DASHBOARD_SALES_GRAPHS = 'DASHBOARD_SALES_GRAPHS';
export const DASHBOARD_SALES = 'DASHBOARD_SALES';
export const DASHBOARD_SALE_DETAIL = 'DASHBOARD_SALE_DETAIL';

export const DEFAULT_PAGE_NUMBER = 1;
export const DEFAULT_PAGE_SIZE = 10;
export const TEN_SECONDS = 10000;