class SettingKeyHelper {
    getCompanyName() {
        return 'COMPANY_NAME';
    }
    getCompanyAddress() {
        return 'COMPANY_ADDRESS';
    }
    getCompanyCity() {
        return 'COMPANY_CITY';
    }
    getCompanyLocation() {
        return 'COMPANY_LOCATION';
    }
    getCompanyEmail() {
        return 'COMPANY_EMAIL';
    }
    getCompanyPhone() {
        return 'COMPANY_PHONE';
    }
    getGracePeriod() {
        return 'GRACE_PERIOD';
    }
    getAllKeys() {
        return [
            this.getCompanyName(),
            this.getCompanyAddress(),
            this.getCompanyCity(),
            this.getCompanyLocation(),
            this.getCompanyEmail(),
            this.getCompanyPhone(),
            this.getGracePeriod()
        ];
    }
}

export default new SettingKeyHelper();