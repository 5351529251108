import { Breadcrumb, Button, Card, Form, Select, Space, Table } from "antd";
import { useContext, useState } from "react";
import { PieChart } from '@mui/x-charts/PieChart';
import { useMediaQuery } from "react-responsive";
import dayjs from "dayjs";
import DateUtil from "../../utils/DateUtil";
import SaleService from "../../services/SaleService";
import { DASHBOARD_REAL_TIME, DASHBOARD_SALE_DETAIL, DASHBOARD_SALES, DASHBOARD_SALES_GRAPHS, DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE } from "../../config/constants";
import { LineChart } from "@mui/x-charts";
import GeneralHelper from "../../helpers/GeneralHelper";
import GeneralUtil from "../../utils/GeneralUtil";
import { SaleDetailProvider } from "../../contexts/SaleDetailContext";
import SaleDetailDescription from "../SaleDetailComponents/SaleDetailDescription";
import SaleDetailConsumptions from "../SaleDetailComponents/SaleDetailConsumptions";
import SaleDetailModal from "../SaleDetailComponents/SaleDetailModal";
import { LayoutContext } from "../../contexts/LayoutContext";

const DahsboardAdminRecords = () => {

    const { dashboardData } = useContext(LayoutContext);

    const [view, setView] = useState(DASHBOARD_REAL_TIME);

    const isMobile = useMediaQuery({ maxWidth: 767 });

    const isDesktop = useMediaQuery({ minWidth: 1200 });

    const [form] = Form.useForm();

    const [monthlySalesChartForm] = Form.useForm();

    const [dataYear, setDataYear] = useState([]);
    const [dataMonth, setDataMonth] = useState([]);

    const [periodSelected, setPeriodSelected] = useState(null);
    const [salesData, setSalesData] = useState([]);
    const [saleSelected, setSaleSelected] = useState(null);
    const [tableParams, setTableParams] = useState(GeneralHelper.getTablePaginationDefault);

    const registrosPorYearService = (year) => {

        SaleService.registrosPorYear(year).then(response => {

            const spanishMonths = DateUtil.GetMonthsSpanish();

            const tempDataYear = [];

            for (let index = 0; index < spanishMonths.length; index++) {

                const finded = response.find(x => Number(x.mes) - 1 === index);

                if (finded === undefined) {
                    tempDataYear.push(0);
                } else {
                    tempDataYear.push(finded.total_monto);
                }

            }

            setDataYear(tempDataYear);

        });

    };

    const registrosPorMesService = (month, year) => {

        SaleService.registrosPorMes(month, year).then(response => {

            const days = DateUtil.GeyDaysByMonthSelect(month, year);

            const tempDataMonth = [];

            for (let index = 1; index <= days.length; index++) {

                const finded = response.find(x => Number(x.dia) === index);

                if (finded === undefined) {
                    tempDataMonth.push(0);
                } else {
                    tempDataMonth.push(finded.total_monto);
                }

            }

            setDataMonth(tempDataMonth);

        });

    }

    useState(() => {
        const current_year = dayjs().year();
        const current_month = dayjs().month() + 1;

        form.setFieldValue('year', current_year);

        monthlySalesChartForm.setFieldValue('month', current_month);
        monthlySalesChartForm.setFieldValue('year', current_year);

        registrosPorMesService(current_month, current_year);

        registrosPorYearService(current_year);

    }, []);

    return (
        <>

            <Space style={{ marginBottom: '20px' }}>
                <Button type={view === DASHBOARD_REAL_TIME ? 'primary' : 'default'} onClick={() => { setView(DASHBOARD_REAL_TIME) }}>Dashboard en tiempo real</Button>
                <Button type={(
                    view === DASHBOARD_SALES_GRAPHS || view === DASHBOARD_SALES || view === DASHBOARD_SALE_DETAIL
                ) ? 'primary' : 'default'} onClick={() => { setView(DASHBOARD_SALES_GRAPHS) }}>Dashboard ventas</Button>
            </Space>

            {view === DASHBOARD_REAL_TIME && (
                <>
                    {dashboardData && (
                        <>
                            <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', justifyContent: 'center', marginBottom: '10px' }}>

                                <Card style={{ width: '300px' }} title={'CUENTAS CLIENTES'}>
                                    <div style={{ textAlign: 'center' }}>
                                        <span>PENDIENTES</span>
                                        <div style={{ fontSize: '20px' }} >{dashboardData.customer_accounts_count}</div>
                                    </div>
                                </Card>

                                <Card style={{ width: '300px' }} title={'HUESPEDES'}>
                                    <div style={{ textAlign: 'center' }}>
                                        <span>HOSPEDADOS</span>
                                        <div style={{ fontSize: '20px' }} >{dashboardData.guests_count}</div>
                                    </div>
                                </Card>

                                <Card style={{ width: '300px' }} title={'LAVANDERÍA'}>
                                    <div style={{ textAlign: 'center' }}>
                                        <span>SOLICITUDES</span>
                                        <div style={{ fontSize: '20px' }} >{dashboardData.laundry_records_counts}</div>
                                    </div>
                                </Card>

                            </div>

                            <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', justifyContent: 'center', marginBottom: '10px' }}>

                                <Card title={`PRODUCTOS: ${Number(dashboardData.product_in_stock_count) + Number(dashboardData.product_out_of_stock_count)}`}>
                                    <div style={{ marginBottom: '20px' }}>
                                        <PieChart
                                            series={[
                                                {
                                                    arcLabel: (item) => `${item.value === 0 ? '' : `${item.value}`}`,
                                                    data: [
                                                        { id: 0, value: dashboardData.product_in_stock_count, label: 'PRODUCTOS CON STOCK', color: '#038512' },
                                                        { id: 1, value: dashboardData.product_out_of_stock_count, label: 'PRODUCTOS SIN STOCK', color: '#a30505' },
                                                    ],
                                                },
                                            ]}
                                            margin={{ right: 5 }}
                                            slotProps={{ legend: { hidden: true } }}
                                            width={250}
                                            height={isMobile ? 100 : 150}
                                        />
                                    </div>
                                    <div>
                                        <span style={{ paddingTop: '1px', paddingRight: '15px', backgroundColor: '#038512', marginRight: '5px' }}></span> PRODUCTOS CON STOCK <br />
                                        <span style={{ paddingTop: '1px', paddingRight: '15px', backgroundColor: '#a30505', marginRight: '5px' }}></span> PRODUCTOS SIN STOCK
                                    </div>
                                </Card>

                                <Card title={`HABITACIONES: ${Number(dashboardData.clean_rooms_count) + Number(dashboardData.dirty_rooms_count)}`}>
                                    <div style={{ marginBottom: '20px' }}>
                                        <PieChart
                                            series={[
                                                {
                                                    arcLabel: (item) => `${item.value === 0 ? '' : `${item.value}`}`,
                                                    data: [
                                                        { id: 0, value: dashboardData.clean_rooms_count, label: 'HABITACIONES LIMPIAS', color: '#038512' },
                                                        { id: 1, value: dashboardData.dirty_rooms_count, label: 'HABITACIONES SUCIAS', color: '#a30505' },
                                                    ],
                                                },
                                            ]}
                                            margin={{ right: 5 }}
                                            slotProps={{ legend: { hidden: true } }}
                                            width={250}
                                            height={isMobile ? 100 : 150}
                                        />
                                    </div>
                                    <div>
                                        <span style={{ paddingTop: '1px', paddingRight: '15px', backgroundColor: '#038512', marginRight: '5px' }}></span> HABITACIONES LIMPIAS <br />
                                        <span style={{ paddingTop: '1px', paddingRight: '15px', backgroundColor: '#a30505', marginRight: '5px' }}></span> HABITACIONES SUCIAS
                                    </div>
                                </Card>

                                <Card title={`RESERVAS: ${Number(dashboardData.booking_count_by_status_pending) + Number(dashboardData.booking_count_by_status_register)}`}>
                                    <div style={{ marginBottom: '20px' }}>
                                        <PieChart
                                            series={[
                                                {
                                                    arcLabel: (item) => `${item.value === 0 ? '' : `${item.value}`}`,
                                                    data: [
                                                        { id: 0, value: dashboardData.booking_count_by_status_pending, label: 'RESERVAS PENDIENTES', color: '#038512' },
                                                        { id: 1, value: dashboardData.booking_count_by_status_register, label: 'RESERVAS POR REGISTRAR', color: '#8d9603' },
                                                    ],
                                                },
                                            ]}
                                            margin={{ right: 5 }}
                                            slotProps={{ legend: { hidden: true } }}
                                            width={250}
                                            height={isMobile ? 100 : 150}
                                        />
                                    </div>
                                    <div>
                                        <span style={{ paddingTop: '1px', paddingRight: '15px', backgroundColor: '#038512', marginRight: '5px' }}></span> RESERVAS PENDIENTES <br />
                                        <span style={{ paddingTop: '1px', paddingRight: '15px', backgroundColor: '#8d9603', marginRight: '5px' }}></span> RESERVAS POR REGISTRAR
                                    </div>
                                </Card>

                            </div>
                        </>
                    )}
                </>
            )}

            {view === DASHBOARD_SALES_GRAPHS && (
                <>
                    <div style={{ marginBottom: '20px' }}>
                        {isDesktop && (
                            <div style={{ backgroundColor: '#FFF', padding: '10px 25px' }}>
                                <Form form={form} layout="vertical">
                                    <div style={{ display: 'flex', gap: '10px' }}>
                                        <Form.Item label={'Año'} name={'year'}>
                                            <Select options={DateUtil.GetYearsForSelect()} style={{ width: '250px' }} onChange={(value) => {
                                                registrosPorYearService(value);
                                            }} />
                                        </Form.Item>
                                    </div>
                                </Form>
                                <LineChart
                                    height={300}
                                    series={[
                                        { data: dataYear, label: `VENTAS ${form.getFieldValue('year')}`, area: true },
                                    ]}
                                    xAxis={[
                                        { scaleType: 'point', data: DateUtil.GetMonthsSpanish() }]
                                    }
                                    onMarkClick={(event, item) => {
                                        const tempMonthSelected = Number(item.dataIndex) + 1;
                                        const tempYearSelected = Number(form.getFieldValue('year'));
                                        setPeriodSelected({ month: tempMonthSelected, year: tempYearSelected });
                                        SaleService.getSalesWithPaginationByMonthAndYear(
                                            tempMonthSelected,
                                            tempYearSelected,
                                            DEFAULT_PAGE_NUMBER,
                                            DEFAULT_PAGE_SIZE
                                        ).then(response => {
                                            setView(DASHBOARD_SALES);
                                            setSalesData(response.data);
                                            setTableParams({
                                                ...tableParams, pagination: {
                                                    ...tableParams.pagination,
                                                    total: response.count
                                                }
                                            });
                                        });
                                    }}
                                />
                            </div>
                        )}
                    </div>

                    {isDesktop && (
                        <div style={{ backgroundColor: '#FFF', padding: '10px 25px' }}>
                            <Form form={monthlySalesChartForm} layout="vertical">
                                <div style={{ display: 'flex', gap: '10px' }}>
                                    <Form.Item label={'Mes'} name={'month'}>
                                        <Select options={DateUtil.GetMonthsForSelect()} style={{ width: '250px' }} onChange={(value) => {
                                            registrosPorMesService(value, monthlySalesChartForm.getFieldValue('year'));
                                        }} />
                                    </Form.Item>
                                    <Form.Item label={'Año'} name={'year'}>
                                        <Select options={DateUtil.GetYearsForSelect()} style={{ width: '250px' }} onChange={(value) => {
                                            registrosPorMesService(monthlySalesChartForm.getFieldValue('month'), value);
                                        }} />
                                    </Form.Item>
                                </div>
                            </Form>
                            <LineChart
                                height={300}
                                series={[
                                    { data: dataMonth, label: `VENTAS ${DateUtil.GetMonthName(monthlySalesChartForm.getFieldValue('month'))} ${monthlySalesChartForm.getFieldValue('year')}`, area: true },
                                ]}
                                xAxis={[{ scaleType: 'point', data: DateUtil.GeyDaysByMonthSelect(monthlySalesChartForm.getFieldValue('month'), monthlySalesChartForm.getFieldValue('year')) }]}
                                onMarkClick={(event, item) => {
                                    const tempDaySelected = Number(item.dataIndex) + 1;
                                    const tempMonthSelected = Number(monthlySalesChartForm.getFieldValue('month'));
                                    const tempYearSelected = Number(monthlySalesChartForm.getFieldValue('year'));
                                    setPeriodSelected({ day: tempDaySelected, month: tempMonthSelected, year: tempYearSelected });
                                    SaleService.getSalesWithPaginationByDate(
                                        tempDaySelected,
                                        tempMonthSelected,
                                        tempYearSelected,
                                        DEFAULT_PAGE_NUMBER,
                                        DEFAULT_PAGE_SIZE
                                    ).then(response => {
                                        setView(DASHBOARD_SALES);
                                        setSalesData(response.data);
                                        setTableParams({
                                            ...tableParams, pagination: {
                                                ...tableParams.pagination,
                                                total: response.count
                                            }
                                        });
                                    });
                                }}
                            />
                        </div>
                    )}
                </>
            )}

            {view === DASHBOARD_SALES && (
                <>
                    <Breadcrumb
                        style={{ marginBottom: '20px' }}
                        items={[
                            { title: <a onClick={() => { setView(DASHBOARD_SALES_GRAPHS); }}>Gráficos</a> },
                            { title: <a>Detalle {DateUtil.GetMonthName(periodSelected.month).toLocaleLowerCase()} {periodSelected?.day} del {periodSelected.year}</a> }
                        ]}
                    />

                    {periodSelected !== null && (
                        <Table
                            pagination={tableParams.pagination}
                            bordered
                            dataSource={salesData}
                            rowKey={(record) => record.id}
                            onChange={(pagination, filters, sorter) => {
                                SaleService.getSalesWithPaginationByMonthAndYear(
                                    periodSelected.month,
                                    periodSelected.year,
                                    pagination.current,
                                    pagination.pageSize
                                ).then(response => {
                                    setSalesData(response.data);
                                    setTableParams({
                                        ...tableParams, pagination: {
                                            ...tableParams.pagination,
                                            current: pagination.current,
                                            pageSize: pagination.pageSize,
                                            total: response.count
                                        }
                                    });
                                });
                            }}
                            columns={[
                                { title: 'Código', width: '100px', dataIndex: 'code', key: 'code', fixed: true },
                                {
                                    title: 'Cliente', key: 'customer', render: (model) => {
                                        return (
                                            <>
                                                <div><strong>Nombre Completo:</strong> {model.name} {model.lastname}</div>
                                                <div><strong>{model.document_type}:</strong> {model.document_number}</div>
                                            </>
                                        );
                                    }
                                },
                                {
                                    title: 'Tipo', dataIndex: 'type', width: '200px', key: 'type', render: (value) => {
                                        return (<>{value}</>);
                                    }
                                },
                                {
                                    title: 'Total', width: '100px', dataIndex: 'total', key: 'total', render: (value) => {
                                        return (<>{GeneralUtil.getMoney(value)}</>);
                                    }
                                },
                                {
                                    title: 'Registrado', key: 'created_at', render: (model) => {
                                        return (<>{DateUtil.ToSimpleString(model.created_at)}</>);
                                    }
                                },
                                {
                                    title: 'Opciones', key: 'Acciones', width: 150, render: (model) => (
                                        <Button
                                            onClick={() => {
                                                setSaleSelected(model);
                                                setView(DASHBOARD_SALE_DETAIL);
                                            }}
                                        >Ver Detalle</Button>
                                    )
                                }
                            ]}
                        />
                    )}
                </>
            )}

            {view === DASHBOARD_SALE_DETAIL && (
                <>
                    <Breadcrumb
                        style={{ marginBottom: '20px' }}
                        items={[
                            { title: <a onClick={() => { setView(DASHBOARD_SALES_GRAPHS); }}>Gráficos</a> },
                            { title: <a onClick={() => { setView(DASHBOARD_SALES) }}>Detalle {DateUtil.GetMonthName(periodSelected.month).toLocaleLowerCase()} {periodSelected.year}</a> },
                            { title: <a>Detalle venta</a> }
                        ]}
                    />

                    <SaleDetailProvider sale={saleSelected}>
                        <SaleDetailDescription />
                        <SaleDetailConsumptions />
                        <SaleDetailModal />
                    </SaleDetailProvider>
                </>
            )}

        </>
    );

}

export default DahsboardAdminRecords;