import { useContext } from "react";
import { MonitoringContext } from "../../contexts/MonitoringContext";
import { Breadcrumb, Button, Card, message, Select, Space, Tag } from "antd";
import { GuestFormProvider } from "../../contexts/GuestFormContext";
import GuestForm from "../GuestFormComponents/GuestForm";
import DateUtil from "../../utils/DateUtil";
import ValidationHelper from "../../helpers/ValidationHelper";
import RoomTypeHelper from "../../helpers/RoomTypeHelper";
import { CleaningMonitoringFormProvider } from "../../contexts/CleaningMonitoringFormContext";
import CleaningMonitoringFormDetail from "../CleaningMonitoringFormComponents/CleaningMonitoringFormDetail";
import CleaningMonitoringForm from "../CleaningMonitoringFormComponents/CleaningMonitoringForm";
import GuestSaleModal from "../GuestFormComponents/GuestSaleModal";
import { useNavigate } from "react-router-dom";
import RoutePathHelper from "../../helpers/RoutePathHelper";
import DisponibilityCard from "./DisponibilityCard";
import ForRegisteringGuestCard from "./ForRegisteringGuestCard";
import { LayoutContext } from "../../contexts/LayoutContext";

const DisponibilityRecords = () => {

    const { monitoringData } = useContext(LayoutContext);

    const {
        showView, setShowView,
        formDataModel, setFormDataModel,
        getFormDataByBookingIdService, setModalExit,
        setModelGuest,
        roomCleaningModel, setRoomCleaningModel,
        getRoomCleaningDetail,
        filterRoom, setFilterRoom
    } = useContext(MonitoringContext);

    const navigate = useNavigate();

    const handlerCreateGuestByBooking = (model) => {

        if (model.room_cleaning_status !== 'LIMPIO') {
            message.warning('LA HABITACIÓN DEBE ESTAR LIMPIA PARA REGISTRAR AL HUESPED.');
            return;
        }

        getFormDataByBookingIdService(model.booking_id);

    };

    const handlerCreateGuest = () => {
        setShowView('create_guest');
    };

    const handlerExitGuest = (model) => {
        setModalExit(true);
        setModelGuest(model);
    };

    const handlerCleanRoom = (room_cleaning_record_id) => {
        getRoomCleaningDetail(room_cleaning_record_id);
    };

    const handlerConsumptions = (model) => {
        navigate(RoutePathHelper.getConsumptionByCustomerAccountId(model.customer_account_id));
    };

    return (
        <>

            {showView === 'disponibilities' && (
                <>
                    <div style={{ display: 'flex', justifyContent: 'end', marginBottom: '20px' }}>
                        <Space>
                            <Select
                                style={{ width: '200px' }}
                                options={[
                                    { key: '1', label: 'TODOS', value: 'ALL' },
                                    { key: '2', label: 'DISPONIBLES', value: 'AVAILABLE' },
                                    { key: '3', label: 'SUCIOS', value: 'DIRTY' },
                                    { key: '4', label: 'OCUPADOS', value: 'BUSY' },
                                ]}
                                value={filterRoom}
                                placeholder={'Filtro por estados'}
                                onChange={(value) => {
                                    setFilterRoom(value);
                                }}
                            />
                            <Button onClick={handlerCreateGuest}>Registrar Huesped</Button>
                        </Space>
                    </div>
                    <div className="MonitoringList">
                        {monitoringData.filter(x => {
                            if (filterRoom === 'BUSY') {
                                return x.is_available === 0;
                            }
                            if (filterRoom === 'AVAILABLE') {
                                return x.is_available === 1 && x.room_cleaning_status === 'LIMPIO';
                            }
                            if (filterRoom === 'ALL') {
                                return x;
                            }
                            if (filterRoom === 'DIRTY') {
                                return x.room_cleaning_status !== 'LIMPIO';
                            }
                        }).map((model, index) => (
                            <div key={index}>

                                {model.is_available === 1 && model.booking_source === 'None' && (
                                    <DisponibilityCard model={model} handlerCleanRoom={handlerCleanRoom} />
                                )}

                                {model.status === 'POR REGISTRAR HUESPED' && model.booking_source === 'Booking' && (
                                    <ForRegisteringGuestCard model={model} handlerCleanRoom={handlerCleanRoom} handlerCreateGuestByBooking={handlerCreateGuestByBooking} />
                                )}

                                {model.is_available === 0 && (
                                    <Card bordered={false} style={{ backgroundColor: '#730a0a', color: '#FFF', height: '100%' }}>
                                        <div style={{ marginBottom: '10px' }}>
                                            <div><strong>Cuenta:</strong> {model.customer_account_code}</div>
                                            <div><strong>Habitación:</strong> {model.room_number}</div>
                                            <div><strong>Tipo:</strong> {RoomTypeHelper.getDescription(model.room_type)}</div>
                                            <div><strong>Cliente:</strong> {model.customer_name} {model.customer_lastname}</div>
                                            {ValidationHelper.hasData(model.company) && (
                                                <div><strong>Empresa:</strong> {model.company}</div>
                                            )}
                                            {model.customer_account_paid ? <div><strong>Pagado: </strong>SI</div> : <div style={{ backgroundColor: '#fcf50f', color: '#000' }}><strong>Pagado: </strong>NO</div>}
                                            <div><strong>Estado:</strong> OCUPADO</div>
                                            <>
                                                {model.room_cleaning_status === 'LIMPIO' && (
                                                    <div><strong>Limpieza:</strong> {model.room_cleaning_status}</div>
                                                )}
                                                {model.room_cleaning_status !== 'LIMPIO' && (
                                                    <div style={{ background: '#d15d04', color: '#000' }}>
                                                        <strong>Limpieza:</strong> {model.room_cleaning_status}
                                                    </div>
                                                )}
                                            </>
                                            <div><strong>Ingreso:</strong> {DateUtil.ToSimpleString(model.disponibility_start_date)}</div>
                                            <div><strong>Salida:</strong> {DateUtil.ToSimpleString(model.disponibility_end_date)}</div>
                                        </div>
                                        {model.room_cleaning_status === 'LIMPIO' && (
                                            <div className="ColumnContainer ColumnContainerVentas" style={{ width: '100%', marginBottom: '10px' }}>
                                                <Button onClick={() => { handlerCleanRoom(model.room_cleaning_record_id); }}>Solicitar Limpieza</Button>
                                            </div>
                                        )}
                                        <div className="ColumnContainer ColumnContainerVentas" style={{ width: '100%', marginBottom: '10px' }}>
                                            <Button onClick={() => { handlerConsumptions(model) }}>Consumos</Button>
                                        </div>
                                        <div className="ColumnContainer ColumnContainerVentas" style={{ width: '100%' }}>
                                            <Button onClick={() => { handlerExitGuest(model); }}>Registrar Salida</Button>
                                        </div>
                                    </Card>
                                )}

                            </div>
                        ))}
                    </div>
                </>
            )}

            {showView === 'create_guest' && (
                <>

                    <Breadcrumb
                        style={{ marginBottom: '20px' }}
                        items={[
                            {
                                title: <div style={{ cursor: 'pointer' }}>Monitoreo</div>,
                                onClick: () => {
                                    setShowView('disponibilities');
                                    setFormDataModel(null);
                                }
                            },
                            {
                                title: <div style={{ cursor: 'default' }}>Nuevo Huesped</div>,
                            },
                        ]}
                    />

                    <GuestFormProvider booking={formDataModel} onBack={() => {
                        setShowView('disponibilities');
                        setFormDataModel(null);
                    }}>
                        <GuestForm />
                        <GuestSaleModal />
                    </GuestFormProvider>

                </>
            )}

            {showView === 'room_cleaning_record_form' && (
                <>
                    <Breadcrumb
                        style={{ marginBottom: '20px' }}
                        items={[
                            {
                                title: <div style={{ cursor: 'pointer' }}>Monitoreo</div>,
                                onClick: () => {
                                    setShowView('disponibilities');
                                    setRoomCleaningModel(null);
                                }
                            },
                            {
                                title: <div style={{ cursor: 'default' }}>Actualizar estado de limpieza</div>,
                            },
                        ]}
                    />
                    <CleaningMonitoringFormProvider
                        room_cleaning_record={roomCleaningModel}
                        type_form={'request'}
                        onBack={() => {
                            setShowView('disponibilities');
                            setRoomCleaningModel(null);
                        }}
                    >
                        <CleaningMonitoringFormDetail />
                        <CleaningMonitoringForm />
                    </CleaningMonitoringFormProvider>
                </>
            )}

        </>
    );
};

export default DisponibilityRecords;