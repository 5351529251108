import { createContext, useState, useEffect } from "react";
import LocalStorageHelper from "../helpers/LocalStorageHelper";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/AuthService";
import TokenHelper from "../helpers/TokenHelper";
import ApiService from "../services/ApiService";
import MenuHelper from "../helpers/MenuHelper";
import RoleKeyHelper from "../helpers/RoleKeyHelper";
import { WEB_URL, WEBSOCKET_URL } from "../config/envConfig";
import { GET_ALL_CURRENT_ROOM_CLEANING_RECORDS, GET_ALL_DISPONIBILITIES, SERVICE_ADMIN_DASHBOARD, TEN_SECONDS, USER_ACTIVE_VERIFICATION, WEBSOCKET_KEEP_ALIVE, WEBSOCKET_SERVICE_LAUNDRY_MONITORING_DATA } from "../config/constants";

export const LayoutContext = createContext();

export const LayoutProvider = ({ children }) => {

    const navigate = useNavigate();

    const user = JSON.parse(LocalStorageHelper.getUserData());

    const [userMenu, setUserMenu] = useState([]);
    const [collapseMenu, setCollapseMenu] = useState(true);
    const [loading, setLoading] = useState(true);
    const [showLayout, setShowLayout] = useState(false);

    const [monitoringData, setMonitoringData] = useState([]);
    const [dashboardData, setDashboardData] = useState(null);
    const [laundryMonitoringData, setLaundryMonitoringData] = useState([]);
    const [cleaningMonitoringData, setCleaningMonitoringData] = useState([]);
    const [cleaningMonitoringWebsocket, setCleaningMonitoringWebsocket] = useState(null);

    const userEmail = JSON.parse(LocalStorageHelper.getUserData()).email;

    const logoutService = () => {
        AuthService.logout(LocalStorageHelper.getTokenId()).catch(err => { });
    }

    const logoutEvent = () => {
        localStorage.clear();
        navigate('/login');
    }

    const handlerTokenExpiration = () => {

        const result = TokenHelper.checkTokenExpiration();

        if (result === 'logout') {
            logoutEvent();
            return;
        }

        if (result === 'update') {
            ApiService.updateTokens();
            return;
        }

    }

    useEffect(() => {
        const intervalId = setInterval(handlerTokenExpiration, 1000);
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {

        if (user.role === RoleKeyHelper.getAdminKey()) {
            const userMenuData = MenuHelper.getAdmin(navigate);
            setUserMenu(userMenuData);
        } else if (user.role === RoleKeyHelper.getHotelCleaningStaff()) {
            const userMenuData = MenuHelper.getCleaningStaff(navigate);
            setUserMenu(userMenuData);
        } else if (user.role === RoleKeyHelper.getLaundryReceptionist()) {
            const userMenuData = MenuHelper.getLaundryReceptionist(navigate);
            setUserMenu(userMenuData);
        } else if (user.role === RoleKeyHelper.getHotelReceptionist()) {
            const userMenuData = MenuHelper.getHotelReceptionist(navigate);
            setUserMenu(userMenuData);
        }

    }, []);

    useEffect(() => {

        let websocket;
        let keepAliveInterval;

        const connectWebSocket = () => {

            fetch(WEB_URL, { method: 'GET', redirect: 'manual' }).then(() => {

                websocket = new WebSocket(WEBSOCKET_URL);

                websocket.onopen = () => {

                    websocket.send(JSON.stringify({ email: userEmail, service: USER_ACTIVE_VERIFICATION }));

                    keepAliveInterval = setInterval(() => {
                        if (websocket.readyState === WebSocket.OPEN) {
                            websocket.send(JSON.stringify({ email: userEmail, service: WEBSOCKET_KEEP_ALIVE }));
                        }
                    }, TEN_SECONDS);

                };

                websocket.onmessage = () => {
                    logoutEvent();
                };

                websocket.onclose = () => {
                    clearInterval(keepAliveInterval);
                    connectWebSocket();
                };

                websocket.onerror = () => {
                    websocket.close();
                };

            });

        };

        connectWebSocket();

        return () => {
            if (websocket) {
                clearInterval(keepAliveInterval);
                websocket.close();
            }
        };

    }, []);

    useEffect(() => {

        let websocket;
        let keepAliveInterval;

        const connectWebSocket = () => {

            fetch(WEB_URL, { method: 'GET', redirect: 'manual' }).then(() => {

                websocket = new WebSocket(WEBSOCKET_URL);

                websocket.onopen = () => {

                    websocket.send(JSON.stringify({ email: userEmail, service: GET_ALL_DISPONIBILITIES }));

                    keepAliveInterval = setInterval(() => {
                        if (websocket.readyState === WebSocket.OPEN) {
                            websocket.send(JSON.stringify({ email: userEmail, service: WEBSOCKET_KEEP_ALIVE }));
                        }
                    }, TEN_SECONDS);

                };

                websocket.onmessage = (event) => {
                    const tempMonitoringData = JSON.parse(event.data);
                    setMonitoringData(tempMonitoringData.data);
                };

                websocket.onclose = () => {
                    clearInterval(keepAliveInterval);
                    connectWebSocket();
                };

                websocket.onerror = () => {
                    websocket.close();
                };

            });

        };

        connectWebSocket();

        return () => {
            if (websocket) {
                clearInterval(keepAliveInterval);
                websocket.close();
            }
        };

    }, []);

    useEffect(() => {

        let websocket;
        let keepAliveInterval;

        const connectWebSocket = () => {

            fetch(WEB_URL, { method: 'GET', redirect: 'manual' }).then(() => {

                websocket = new WebSocket(WEBSOCKET_URL);

                websocket.onopen = () => {

                    websocket.send(JSON.stringify({ email: userEmail, service: SERVICE_ADMIN_DASHBOARD }));

                    keepAliveInterval = setInterval(() => {
                        if (websocket.readyState === WebSocket.OPEN) {
                            websocket.send(JSON.stringify({ email: userEmail, service: WEBSOCKET_KEEP_ALIVE }));
                        }
                    }, TEN_SECONDS);

                };

                websocket.onmessage = (event) => {
                    const tempDashboardData = JSON.parse(event.data);
                    setDashboardData(tempDashboardData.data);
                };

                websocket.onclose = (event) => {
                    clearInterval(keepAliveInterval);
                    connectWebSocket();
                };

                websocket.onerror = (error) => {
                    websocket.close();
                };

            });

        };

        connectWebSocket();

        return () => {
            if (websocket) {
                clearInterval(keepAliveInterval);
                websocket.close();
            }
        };

    }, []);

    useEffect(() => {

        let websocket;
        let keepAliveInterval;

        const connectWebSocket = () => {

            fetch(WEB_URL, { method: 'GET', redirect: 'manual' }).then(() => {

                websocket = new WebSocket(WEBSOCKET_URL);

                websocket.onopen = () => {

                    websocket.send(JSON.stringify({ email: userEmail, service: WEBSOCKET_SERVICE_LAUNDRY_MONITORING_DATA }));

                    keepAliveInterval = setInterval(() => {
                        if (websocket.readyState === WebSocket.OPEN) {
                            websocket.send(JSON.stringify({ email: userEmail, service: WEBSOCKET_KEEP_ALIVE }));
                        }
                    }, TEN_SECONDS);

                };

                websocket.onmessage = (event) => {
                    const tempLaundryMonitoringData = JSON.parse(event.data);
                    setLaundryMonitoringData(tempLaundryMonitoringData.data)
                };

                websocket.onclose = (event) => {
                    clearInterval(keepAliveInterval);
                    connectWebSocket();
                };

                websocket.onerror = (error) => {
                    websocket.close();
                };

            });

        };

        connectWebSocket();

        return () => {
            if (websocket) {
                clearInterval(keepAliveInterval);
                websocket.close();
            }
        };

    }, []);

    useEffect(() => {

        let websocket;
        let keepAliveInterval;

        const connectWebSocket = () => {

            fetch(WEB_URL, { method: 'GET', redirect: 'manual' }).then(() => {

                websocket = new WebSocket(WEBSOCKET_URL);

                websocket.onopen = () => {

                    websocket.send(JSON.stringify({ email: userEmail, service: GET_ALL_CURRENT_ROOM_CLEANING_RECORDS, params: { status: 'SUCIO' } }));

                    keepAliveInterval = setInterval(() => {
                        if (websocket.readyState === WebSocket.OPEN) {
                            websocket.send(JSON.stringify({ email: userEmail, service: WEBSOCKET_KEEP_ALIVE }));
                        }
                    }, TEN_SECONDS);

                };

                websocket.onmessage = (event) => {
                    const tempCleaningMonitoringData = JSON.parse(event.data);
                    setCleaningMonitoringData(tempCleaningMonitoringData.data)
                };

                websocket.onclose = (event) => {
                    clearInterval(keepAliveInterval);
                    connectWebSocket();
                };

                websocket.onerror = (error) => {
                    websocket.close();
                };

                setCleaningMonitoringWebsocket(websocket);

            });

        };

        connectWebSocket();

        return () => {
            if (websocket) {
                clearInterval(keepAliveInterval);
                websocket.close();
            }
        };

    }, []);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    useEffect(() => {
        if (!loading) setShowLayout(true);
    }, [loading]);

    return (
        <LayoutContext.Provider value={{
            userMenu, loading, showLayout,
            collapseMenu, setCollapseMenu,
            logoutService, logoutEvent,
            monitoringData, dashboardData, laundryMonitoringData,
            cleaningMonitoringData, cleaningMonitoringWebsocket
        }}>
            {children}
        </LayoutContext.Provider>
    );
};