import { createContext, useEffect, useState } from "react";
import { WEBSOCKET_URL } from "../config/envConfig";
import LocalStorageHelper from "../helpers/LocalStorageHelper";
import BookingService from "../services/BookingService";
import GuestService from "../services/GuestService";
import { Form, message } from "antd";
import RoomCleaningService from "../services/RoomCleaningService";
import { GET_ALL_DISPONIBILITIES } from "../config/constants";

export const MonitoringContext = createContext();

export const MonitoringProvider = ({ children }) => {

    const [showView, setShowView] = useState('disponibilities');

    const [formDataModel, setFormDataModel] = useState(null);

    const [modalExit, setModalExit] = useState(false);

    const [modelGuest, setModelGuest] = useState(null);

    const [roomCleaningModel, setRoomCleaningModel] = useState(null);

    const [formSale] = Form.useForm();

    const [loading, setLoading] = useState(false);

    const [filterRoom, setFilterRoom] = useState('ALL');

    const getFormDataByBookingIdService = (booking_id) => {
        BookingService.formData(booking_id).then(response => {
            setFormDataModel(response);
            setShowView('create_guest');
        }).catch(error => {
            console.log(error);
            message.error('Ocurrio un error con el servicio.');
        });
    };

    const getExitGuestService = (values) => {
        setLoading(true);
        GuestService.updateExit(modelGuest.guest_id, values).then(response => {
            message.success('Se registró la salida y la venta del huesped.');
            setModalExit(false);
            setModelGuest(null);
            formSale.resetFields();
        }).catch(error => {
            console.log(error);
            message.error('Ocurrio un error con el servicio.');
        }).finally(() => {
            setLoading(false);
        });
    };

    const getRoomCleaningDetail = (room_cleaning_record_id) => {
        RoomCleaningService.getDetail(room_cleaning_record_id).then(response => {
            setRoomCleaningModel(response);
            setShowView('room_cleaning_record_form');
        }).catch(error => {
            console.log(error);
            console.log('Ocurrio un error con el servicio.');
        });
    };

    return (
        <MonitoringContext.Provider value={{
            showView, setShowView,
            formDataModel, setFormDataModel,
            getFormDataByBookingIdService, getExitGuestService,
            modalExit, setModalExit,
            modelGuest, setModelGuest,
            roomCleaningModel, setRoomCleaningModel,
            getRoomCleaningDetail,
            formSale, loading,
            filterRoom, setFilterRoom
        }}>
            {children}
        </MonitoringContext.Provider>
    );

};