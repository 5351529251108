import { useContext, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { SaleContext } from "../../contexts/SaleContext";
import { Button, Card, Dropdown, List, message, Modal, Pagination, Table } from "antd";
import GeneralUtil from "../../utils/GeneralUtil";
import DateUtil from "../../utils/DateUtil";
import { SettingOutlined } from "@ant-design/icons";
import SaleService from "../../services/SaleService";

const SaleRecords = () => {

    const isMobile = useMediaQuery({ maxWidth: 767 });

    const [saleModal, setSaleModal] = useState({
        open: false,
        record: null
    });

    const {
        data, tableParams, getSalesByPagination,
        setShowView, setSaleSelected, getSalesDefault
    } = useContext(SaleContext);

    const onChangeTable = (pagination, filters, sorter) => {
        getSalesByPagination(pagination.current, pagination.pageSize);
    };

    const onChangeList = (page, pageSize) => {
        getSalesByPagination(page, pageSize);
    };

    const onShowSizeChange = (current, size) => {
        getSalesByPagination(current, size);
    };

    const handlerShowDetail = (model) => {
        setSaleSelected(model);
        setShowView('sale_detail');
    };

    return (
        <>
            {
                isMobile ?
                    <>
                        <List
                            itemLayout="vertical"
                            dataSource={data}
                            renderItem={(model, index) => (
                                <List.Item>
                                    <Card bordered={false}>
                                        <div className="RowContainer">
                                            <div className="ColumnContainer">
                                                <div><strong>Código:</strong> {model.code}</div>
                                                <div><strong>Total:</strong> {GeneralUtil.getMoney(model.total)}</div>
                                                <div><strong>Cliente:</strong> {model.name} {model.lastname}</div>
                                                <div><strong>{model.document_type}:</strong> {model.document_number}</div>
                                                <div><strong>Tipo:</strong> {model.type}</div>
                                                <div><strong>Transacción:</strong> {model.type_of_transfer}</div>
                                                <div><strong>Registrado:</strong> {DateUtil.ToSimpleString(model.created_at)}</div>
                                            </div>
                                            <div className="ColumnContainer ColumnContainerVentas" style={{ width: '100%' }}>
                                                <Dropdown menu={{
                                                    items: [
                                                        {
                                                            key: '1', label: 'Ver Detalle', onClick: () => {
                                                                handlerShowDetail(model);
                                                            }
                                                        },
                                                        {
                                                            key: '2', label: 'Eliminar', onClick: () => {
                                                                setSaleModal({ open: true, record: model });
                                                            }
                                                        }
                                                    ]
                                                }} trigger={['click']} >
                                                    <Button icon={<SettingOutlined />}>Acciones</Button>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </Card>
                                </List.Item>
                            )}
                        />
                        <Pagination
                            pageSize={tableParams.pagination.pageSize}
                            showSizeChanger={tableParams.pagination.showSizeChanger}
                            onShowSizeChange={onShowSizeChange}
                            total={tableParams.pagination.total}
                            pageSizeOptions={tableParams.pagination.pageSizeOptions}
                            current={tableParams.pagination.current}
                            onChange={onChangeList}
                        />
                    </> :
                    <Table
                        pagination={tableParams.pagination}
                        bordered
                        dataSource={data}
                        rowKey={(record) => record.id}
                        onChange={onChangeTable}
                        columns={[
                            { title: 'Código', width: '100px', dataIndex: 'code', key: 'code', fixed: true },
                            {
                                title: 'Cliente', key: 'customer', render: (model) => {
                                    return (
                                        <>
                                            <div><strong>Nombre Completo:</strong> {model.name} {model.lastname}</div>
                                            <div><strong>{model.document_type}:</strong> {model.document_number}</div>
                                        </>
                                    );
                                }
                            },
                            {
                                title: 'Tipo', dataIndex: 'type', width: '150px', key: 'type', render: (value) => {
                                    return (<>{value}</>);
                                }
                            },
                            {
                                title: 'Transacción', dataIndex: 'type_of_transfer', width: '120px', key: 'type_of_transfer', render: (value) => {
                                    return (<>{value}</>);
                                }
                            },
                            {
                                title: 'Total', width: '100px', dataIndex: 'total', key: 'total', render: (value) => {
                                    return (<>{GeneralUtil.getMoney(value)}</>);
                                }
                            },
                            {
                                title: 'Registrado', key: 'created_at', render: (model) => {
                                    return (<>{DateUtil.ToSimpleString(model.created_at)}</>);
                                }
                            },
                            {
                                title: 'Opciones', key: 'Acciones', width: 150, render: (model) => (
                                    <Dropdown menu={{
                                        items: [
                                            {
                                                key: '1', label: 'Ver Detalle', onClick: () => {
                                                    handlerShowDetail(model);
                                                }
                                            },
                                            {
                                                key: '2', label: 'Eliminar', onClick: () => {
                                                    setSaleModal({ open: true, record: model });
                                                }
                                            }
                                        ]
                                    }} trigger={['click']} >
                                        <Button icon={<SettingOutlined />}>Acciones</Button>
                                    </Dropdown>
                                )
                            }
                        ]}
                    />
            }

            <Modal
                open={saleModal.open}
                title={'Eliminar Venta'}
                footer={[
                    <Button key='1' onClick={() => { setSaleModal({ ...saleModal, open: false, record: null }) }}>Regresar</Button>,
                    <Button key='2' type="primary" onClick={() => {
                        SaleService.delete(saleModal.record.id).then(() => {
                            getSalesDefault();
                            setSaleModal({ open: false, record: null });
                            message.success('Se eliminó la venta.');
                        });
                    }}>Eliminar</Button>
                ]}
                onCancel={() => { setSaleModal({ ...saleModal, open: false, record: null }) }}
            >
                {saleModal.record !== null && (
                    <p>Se liminará la información de la venta <strong>{saleModal.record.code}</strong> de <strong>{saleModal.record.name} {saleModal.record.lastname}</strong> con un monto de <strong>{GeneralUtil.getMoney(saleModal.record.total)}</strong> y todos los registros relacionados, ¿Seguro que desea continuar?</p>
                )}

            </Modal>
        </>
    );
};

export default SaleRecords;